<template>
  <main class="materials-styles">
    <div class="horiz-container">
      <div class="flex items-center gap-3 text-light mb-10 breadcrumbs">
        <NuxtLink :to="{name: 'materials'}" class="link-subnav active">Мои материалы</NuxtLink>
        <span class="icon-chevron-right"></span>
        <NuxtLink :to="$route" class="link-subnav">{{ form.id ? `Редактирование секции: "${form.name}"` : 'Создание секции' }}</NuxtLink>
      </div>
      <BaseInput v-model="form.name" :error="errors.name"
                 type="text" class="input-text input-h2 bg-transparent mb-4" placeholder="Введите название"/>
      <button class="btn btn-lg btn-reversed mt-8" @click="submit">Сохранить</button>
    </div>
  </main>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { getSection, storeSection, updateSection } from '@/api/materials'
import BaseInput from '@/components/common/Input'

const router = useRouter()
const route = useRoute()
const editor = ref(null)

const {
  form,
  errors,
  loading,
  submit
} = useForm(async data => {
  return (form.value.id
    ? updateSection(route.params.sectionId, data)
    : storeSection(data))
}, () => {
  router.push({
    name: 'materials'
  })
})

useHead({
  title: computed(() => form.value?.id ? `Редактирование секции - ${form.value?.name}` : 'Создание секции')
})

const { data: section } = await useAsyncData(async () => route.params.sectionId ? await getSection(route.params.sectionId) : {})
if (!section.value) throw createNotFoundError()

watchEffect(() => {
  form.value = section.value
})
</script>

<style lang="scss">
@import "@/assets/css/materials";
</style>
